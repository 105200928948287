@font-face {
    font-family: 'Gotham Rounded';
    src: url('../fonts/GothamRounded/GothamRnd-Bold.otf') format("opentype");
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham Rounded';
    src: url('../fonts/GothamRounded/GothamRnd-BoldIta.otf') format("opentype");
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Gotham Rounded';
    src: url('../fonts/GothamRounded/GothamRnd-Book.otf') format("opentype");
    font-weight: 350;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham Rounded';
    src: url('../fonts/GothamRounded/GothamRnd-BookIta.otf') format("opentype");
    font-weight: 350;
    font-style: italic;
}

@font-face {
    font-family: 'Gotham Rounded';
    src: url('../fonts/GothamRounded/GothamRnd-Light.otf') format("opentype");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Gotham Rounded';
    src: url('../fonts/GothamRounded/GothamRnd-LightIta.otf') format("opentype");
    font-weight: 300;
    font-style: italic;
}
@font-face {
    font-family: 'Gotham Rounded';
    src: url('../fonts/GothamRounded/GothamRnd-MedIta.otf') format("opentype");
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Gotham Rounded';
    src: url('../fonts/GothamRounded/GothamRnd-Medium.otf') format("opentype");
    font-weight: 500;
    font-style: normal;
}