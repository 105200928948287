.chapter-wrapper {
	display: flex;
	flex-direction: column;
	flex-grow: 1;


	.content {
		background-color: white;
		padding: 1rem;
		flex-grow: 1;

		p {
			line-height: 1.6;
		}

		u {
			color: var(--purple);
		}

		img {
			max-width: 40rem;
		}
	}



	.chapter-header {
		position: sticky;
		top: 0px;
		background-color: white;
		z-index: 20;
		box-shadow: 0px 2px 6px rgb(0, 0, 0, 0.1);
		padding: 0.3rem 1rem;
		display: flex;
		align-items: center;

		.index-item {
			padding: 0 0.5rem;
			border-bottom: unset;
			flex-grow: 1;
		}

		.burger-toggle {
			flex-shrink: 0
		}
	}
}

.chapter-navigation {
	position: sticky;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: var(--purple);
	padding: 0.5rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
	color: white;

	img {
		width: 30px;
		height: 100%;
	}

	.nav-btns {
		display: flex;
		column-gap: 0.4rem;
	}

	.legal-links {
		a {
			font-size: 0.75rem;
		}
	}

	.nav-arrows {
		display: flex;
		gap: 0.6rem;
	}
}

.map-preview {
	img {
		width: 100%;
	}
}
.map-wrapper {
	position: relative;
	display: none;
	height: 100%;
	flex-direction: column;
	
	.map-title {
		margin-bottom: 0.6rem;
	}

	iframe {
		width: 100%;
		flex-grow: 1;
	}
	.map-img {
		width: 200px;
	}
}