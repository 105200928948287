.header-wrapper{
    display: flex;
    flex-direction: column;

    &.open {
        position: fixed;
        inset: 0;
        min-height: 100%;
        height: 100%;
        z-index: 50;
    }

    .header{
        color: white;
        background-color: var(--purple-dark);
        transform: translateY(0);
        transition: transform 300ms ease-in-out;
        padding: 1.5rem;
        display: flex;
    
        &__content{    
            flex-grow: 1;
            span, .logo{
                font-size: 1.5rem;
            }
    
            .logo{
                margin-top: 0.2rem;
            }
    
            h1{
                margin-top: 0.3rem;
                margin-bottom: 0;
            }
        }
        
        &.hide{
            transform: translateY(-100%);
        }  

        .burger-toggle {
            position: fixed;
            top: 1rem;
            right: 1rem;

        }
    
        img{
            align-self: flex-start;
            cursor: pointer;
            transition: opacity ease 200ms;

            &.lock{
                pointer-events: none;
            }
        }
    
    }
    
    
    .sidenav{
        background-color: white;
        overflow: auto;
        
        &.open{
            display: block;
            height: 100%;
        }
    }
}