@font-face {
  font-family: "Gotham Rounded";
  src: url("../fonts/GothamRounded/GothamRnd-Bold.otf") format("opentype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Gotham Rounded";
  src: url("../fonts/GothamRounded/GothamRnd-BoldIta.otf") format("opentype");
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: "Gotham Rounded";
  src: url("../fonts/GothamRounded/GothamRnd-Book.otf") format("opentype");
  font-weight: 350;
  font-style: normal;
}
@font-face {
  font-family: "Gotham Rounded";
  src: url("../fonts/GothamRounded/GothamRnd-BookIta.otf") format("opentype");
  font-weight: 350;
  font-style: italic;
}
@font-face {
  font-family: "Gotham Rounded";
  src: url("../fonts/GothamRounded/GothamRnd-Light.otf") format("opentype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Gotham Rounded";
  src: url("../fonts/GothamRounded/GothamRnd-LightIta.otf") format("opentype");
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: "Gotham Rounded";
  src: url("../fonts/GothamRounded/GothamRnd-MedIta.otf") format("opentype");
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: "Gotham Rounded";
  src: url("../fonts/GothamRounded/GothamRnd-Medium.otf") format("opentype");
  font-weight: 500;
  font-style: normal;
}
:root {
  --background: hsl(270, 30%, 30%);
  --purple: hsl(280, 70%, 55%);
  --purple-light: hsl(280, 70%, 91%);
  --purple-dark: hsl(248, 56%, 16%);
  --pink: hsl(288, 63%, 71%);
  --gray: hsl(248, 9%, 83%);
  --font: "Gotham Rounded", sans-serif;
}

html {
  background-color: var(--purple-dark);
  font-family: var(--font);
  scroll-behavior: smooth;
}

html, body {
  height: 100%;
}

body,
*::before,
*::after {
  margin: 0;
  box-sizing: border-box;
}

a {
  -webkit-tap-highlight-color: transparent;
}

a.button {
  text-decoration: none;
}

sup {
  cursor: pointer;
}

@media (pointer: fine) {
  *::-webkit-scrollbar {
    width: 16px;
    height: 16px;
  }

  *::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }

  *::-webkit-scrollbar-thumb {
    border: 4px solid transparent;
    border-radius: 100px;
    background-color: #babac0;
    background-clip: content-box;
  }
  *::-webkit-scrollbar-thumb:hover {
    opacity: 0.4;
  }

  *::-webkit-scrollbar-thumb:hover {
    background-color: #a0a0a5;
  }
}
[x-cloak] {
  display: none !important;
}

.page-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.page-wrapper.references-page .abstract-container {
  position: fixed;
  background-color: white;
}

.no-scroll {
  overflow-y: hidden;
}

.logo {
  display: inline-block;
  color: var(--purple);
  border-radius: 0.5rem;
  border: 4px solid var(--purple);
  padding: 0.25rem 0.6rem;
  width: fit-content;
}

.button {
  font-family: var(--font);
  padding: 1rem;
  color: white;
  background-color: var(--purple);
  border: none;
  border-radius: 0.4rem;
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: 0.2px;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
}
.button--slim {
  font-weight: 400;
  font-size: 0.8rem;
  width: fit-content;
  padding: 0.5rem;
}

@media (pointer: coarse) {
  .reactive {
    transition: transform ease 100ms;
    -webkit-tap-highlight-color: transparent;
  }
  .reactive:active {
    opacity: 0.6;
    transform: scale(0.9);
  }
}
@media (pointer: fine) {
  .reactive:hover {
    opacity: 0.8;
  }
  .reactive:active, .reactive:focus {
    opacity: 0.6;
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    visibility: hidden;
  }
}
.content-notice-wrapper {
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  inset: 0;
  z-index: 1000;
  display: grid;
  place-items: center;
}

.content-notice {
  position: absolute;
  background-color: white;
  margin: 1rem;
  max-width: min(600px, 90vw);
  max-height: max(400px, 90vh);
  padding: 1.5rem;
  border-radius: 0.5rem;
}
@media (min-width: 768px) {
  .content-notice {
    text-align: center;
  }
}
.content-notice__title {
  font-size: 1.7rem;
  font-weight: bold;
  color: var(--purple);
}
@media (min-width: 768px) {
  .content-notice__title {
    font-size: 2rem;
  }
}
.content-notice__body {
  margin-top: 1rem;
  font-weight: 300;
  line-height: 1.5;
  font-size: 1rem;
}
.content-notice__footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 1rem;
  margin-top: 2.5rem;
  flex-wrap: wrap;
}

.splash-screen {
  --margin-container: 10vw;
  position: fixed;
  inset: 0;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.9), transparent 50%), url("../assets/img/galaxy.png"), var(--purple-dark);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  color: white;
  user-select: none;
  transition: visibility 0s, opacity 1s ease-in;
  z-index: 100;
  display: flex;
  flex-direction: column;
}
.splash-screen.hide {
  animation: fade-out 2s 500ms forwards;
}
.splash-screen.hide .button {
  animation: fade-out 1.5s forwards;
}
.splash-screen__content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-inline: var(--margin-container);
  animation: fade-in 4s forwards;
}
.splash-screen__content h2 {
  font-size: 2.5rem;
  margin-block: 0.75rem;
}
@media (max-width: 400px) {
  .splash-screen__content h2 {
    font-size: 1.8rem;
  }
}
.splash-screen__content h1 {
  margin-top: 1rem;
  font-size: 3rem;
}
@media (max-width: 400px) {
  .splash-screen__content h1 {
    font-size: 2.3rem;
  }
}
.splash-screen__content .logo {
  font-size: 2.5rem;
}
@media (max-width: 400px) {
  .splash-screen__content .logo {
    font-size: 2rem;
  }
}
.splash-screen__content .button {
  opacity: 0;
  max-width: 400px;
  animation: fade-in 200ms 1s forwards;
}
.splash-screen__content .button:not(.cta) {
  background-color: var(--pink);
}
.splash-screen__footer {
  margin-inline: 5vw 3vw;
  font-weight: 300;
  font-size: 0.8rem;
  line-height: 1.5;
  opacity: 0.8;
  margin-bottom: 1.5rem;
  display: flex;
  column-gap: 0.6rem;
}
@media (min-width: 512px) {
  .splash-screen__footer {
    margin-inline: var(--margin-container);
    column-gap: 2rem;
    font-size: 0.9rem;
  }
}
.splash-screen__footer .logos {
  flex-basis: 33%;
  flex-shrink: 0;
  display: flex;
  flex-wrap: wrap;
  column-gap: 1.3rem;
  row-gap: 0.2rem;
  margin-top: 1rem;
  margin-left: auto;
  justify-content: end;
}
.splash-screen__footer .logos img {
  width: 100%;
  max-width: 120px;
}
@media (max-width: 400px) {
  .splash-screen__footer .logos img {
    max-width: 100px;
  }
}
.splash-screen .credits-popup h2,
.splash-screen .credits-popup h3 {
  color: var(--purple);
}
.splash-screen .credits-popup h2 {
  font-weight: 700;
}
.splash-screen .credits-popup h3 {
  font-weight: 400;
}
.splash-screen .credits-popup p {
  font-weight: 300;
}
.splash-screen .credits-popup button {
  appearance: none;
  border: none;
  background: none;
  font-family: var(--font);
  color: var(--purple);
  font-weight: 800;
  text-decoration: underline;
  padding: 0;
}

.legal-links a {
  font-size: 0.85rem;
  color: white;
  text-decoration: none;
  font-weight: 300;
}

.index > a {
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
}
@media (pointer: coarse) {
  .index > a:active img {
    transform: rotateZ(180deg) scale(0.9);
  }
}

.index-item {
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  border-bottom: 1px solid var(--gray);
}
.index-item__index {
  font-size: 3rem;
  color: var(--purple);
  margin-block: 0;
  border-right: 2px solid var(--purple-light);
  padding-right: 1rem;
  text-align: center;
  min-width: 35px;
  flex-shrink: 0;
}
.index-item__title {
  flex-grow: 1;
  margin-inline: 1rem;
  font-size: 1.2rem;
  font-weight: bold;
  color: var(--purple-dark);
}
.index-item__icon {
  width: 50px;
  transform: rotateZ(180deg);
  transition: transform 200ms ease;
}
@media (pointer: fine) {
  .index-item__icon:hover {
    transform: rotateZ(180deg) scale(1.1);
  }
}
@media (pointer: coarse) {
  .index-item__icon:active {
    transform: rotateZ(180deg) scale(1.1);
  }
}

.subindex {
  color: var(--purple);
  font-size: 1.2rem;
}

figure.table {
  overflow-x: auto;
  margin: 0;
}

.abstract-container {
  position: absolute;
  inset: 0;
  background-color: white;
  z-index: 10;
  overflow: auto;
  border-radius: 0.5rem;
}
.abstract-container .abstract__heading {
  font-size: 1.2rem;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.15);
  position: sticky;
  top: 0;
  padding: 1rem;
}
.abstract-container .abstract__content {
  padding: 1rem;
  font-size: 0.9rem;
  line-height: 1.6;
  font-weight: 300;
  overscroll-behavior: contain;
}

.header-wrapper {
  display: flex;
  flex-direction: column;
}
.header-wrapper.open {
  position: fixed;
  inset: 0;
  min-height: 100%;
  height: 100%;
  z-index: 50;
}
.header-wrapper .header {
  color: white;
  background-color: var(--purple-dark);
  transform: translateY(0);
  transition: transform 300ms ease-in-out;
  padding: 1.5rem;
  display: flex;
}
.header-wrapper .header__content {
  flex-grow: 1;
}
.header-wrapper .header__content span, .header-wrapper .header__content .logo {
  font-size: 1.5rem;
}
.header-wrapper .header__content .logo {
  margin-top: 0.2rem;
}
.header-wrapper .header__content h1 {
  margin-top: 0.3rem;
  margin-bottom: 0;
}
.header-wrapper .header.hide {
  transform: translateY(-100%);
}
.header-wrapper .header .burger-toggle {
  position: fixed;
  top: 1rem;
  right: 1rem;
}
.header-wrapper .header img {
  align-self: flex-start;
  cursor: pointer;
  transition: opacity ease 200ms;
}
.header-wrapper .header img.lock {
  pointer-events: none;
}
.header-wrapper .sidenav {
  background-color: white;
  overflow: auto;
}
.header-wrapper .sidenav.open {
  display: block;
  height: 100%;
}

.popup {
  position: fixed;
  inset: 0;
  z-index: 50;
  background-color: white;
  border-radius: 0.5rem;
  padding-block: 0.5rem 1rem;
  display: flex;
  flex-direction: column;
  color: black;
  max-width: min(800px, 90vw);
  max-height: max(400px, 90vh);
  margin: auto;
  isolation: isolate;
}
.popup__backdrop {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.4);
  mix-blend-mode: overlay;
  z-index: -1;
}
.popup__close {
  text-align: end;
  padding-inline: 0.5rem;
  background-color: white;
  cursor: pointer;
}
.popup__content {
  position: static;
  flex-grow: 1;
  overflow-y: auto;
  overscroll-behavior: contain;
  padding-inline: 1rem;
  background-color: white;
}
.popup__content .button {
  font-weight: 400;
  font-size: 0.8rem;
  width: fit-content;
  padding: 0.5rem;
}
.popup__content .map-wrapper {
  display: flex;
}
.popup__content iframe {
  width: 100%;
  min-height: 500px;
  border-radius: 6px;
}
.popup .loader {
  display: none;
}
.popup.loading .popup__content {
  display: grid;
  place-items: center;
}
.popup.loading .loader {
  display: block;
}

.loader {
  border: 4px solid var(--purple-light);
  border-left-color: transparent;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.img-wrapper {
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
}

.reference-wrapper {
  padding-block: 1rem;
}
.reference-wrapper:first-child {
  padding-block-start: 0.5rem;
}
.reference-wrapper:not(:last-child) {
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
}
.reference-wrapper .reference-item {
  display: flex;
  flex-direction: column;
  row-gap: 0.6rem;
}
.reference-wrapper .reference__title {
  font-size: 1.2rem;
  font-weight: 500;
}
.reference-wrapper .reference__authors {
  font-size: 0.85rem;
  font-weight: 300;
}
.reference-wrapper .reference__basic {
  color: var(--purple);
  font-weight: 500;
  font-size: 0.95rem;
}
.reference-wrapper .reference__link {
  color: var(--purple-light);
  font-size: 0.8rem;
  color: var(--pink);
  text-decoration: unset;
  font-weight: 300;
  width: fit-content;
}
.reference-wrapper .button {
  margin-top: 0.5rem;
}

.chapter-wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.chapter-wrapper .content {
  background-color: white;
  padding: 1rem;
  flex-grow: 1;
}
.chapter-wrapper .content p {
  line-height: 1.6;
}
.chapter-wrapper .content u {
  color: var(--purple);
}
.chapter-wrapper .content img {
  max-width: 40rem;
}
.chapter-wrapper .chapter-header {
  position: sticky;
  top: 0px;
  background-color: white;
  z-index: 20;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  padding: 0.3rem 1rem;
  display: flex;
  align-items: center;
}
.chapter-wrapper .chapter-header .index-item {
  padding: 0 0.5rem;
  border-bottom: unset;
  flex-grow: 1;
}
.chapter-wrapper .chapter-header .burger-toggle {
  flex-shrink: 0;
}

.chapter-navigation {
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--purple);
  padding: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
}
.chapter-navigation img {
  width: 30px;
  height: 100%;
}
.chapter-navigation .nav-btns {
  display: flex;
  column-gap: 0.4rem;
}
.chapter-navigation .legal-links a {
  font-size: 0.75rem;
}
.chapter-navigation .nav-arrows {
  display: flex;
  gap: 0.6rem;
}

.map-preview img {
  width: 100%;
}

.map-wrapper {
  position: relative;
  display: none;
  height: 100%;
  flex-direction: column;
}
.map-wrapper .map-title {
  margin-bottom: 0.6rem;
}
.map-wrapper iframe {
  width: 100%;
  flex-grow: 1;
}
.map-wrapper .map-img {
  width: 200px;
}

.references-page .references-list {
  background-color: white;
}
.references-page .references-list .reference-item {
  padding: 1rem;
}
.references-page .chapter-wrapper .content {
  padding: 0;
}
.references-page .abstract-container {
  isolation: isolate;
  background-color: white;
  margin: auto;
  max-width: min(600px, 90vw);
  max-height: min(800px, 90vh);
  z-index: 1000;
}
.references-page .abstract-container__backdrop {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.4);
  mix-blend-mode: overlay;
  z-index: -1;
}