html{
    background-color: var(--purple-dark);
    font-family: var(--font);
    scroll-behavior: smooth;
}

html,body {
    height: 100%;
}


body,
*::before,
*::after {
    margin: 0;
    box-sizing: border-box;
}

a {
    -webkit-tap-highlight-color: transparent;
}

a.button {
    text-decoration: none;
}

sup {
    cursor: pointer;
}

@media (pointer:fine){
    *::-webkit-scrollbar {
        width: 16px;
        height: 16px;
    }

    *::-webkit-scrollbar-track {
        background-color: #f1f1f1;
    }

    *::-webkit-scrollbar-thumb {
        border: 4px solid transparent;
        border-radius: 100px;
        background-color: #babac0;
        background-clip: content-box;

        &:hover {
            opacity: 0.4;
        }
    }

    *::-webkit-scrollbar-thumb:hover {
        background-color: #a0a0a5;
    }
}

[x-cloak]{
    display: none !important;
}

.page-wrapper {
	height: 100%;
	display: flex;
	flex-direction: column;

    &.references-page {
        .abstract-container {
            position: fixed;
            background-color: white;
        }
    }
}


.no-scroll{
    overflow-y: hidden;
}

.logo{
    display: inline-block;
    color: var(--purple);
    border-radius: 0.5rem;
    border: 4px solid var(--purple);
    padding: 0.25rem 0.6rem;
    width: fit-content;
}

.button {
    font-family: var(--font);
    padding: 1rem;
    color: white;
    background-color: var(--purple);
    border: none;
    border-radius: 0.4rem;
    font-weight: 800;
    text-transform: uppercase;
    letter-spacing: 0.2px;
    -webkit-tap-highlight-color: transparent;
    cursor: pointer;

    &--slim {
        font-weight: 400;
        font-size: 0.8rem;
        width: fit-content;
        padding: 0.5rem;
    }
}

.reactive {
    @media (pointer: coarse){
        transition:  transform ease 100ms;
        -webkit-tap-highlight-color: transparent;
        &:active{
            opacity: 0.6;
            transform: scale(0.9);
        }
    }

    @media (pointer: fine){
        &:hover{
            opacity: 0.8;
        }

        &:active, &:focus{
            opacity: 0.6;
        }
    }
}


@keyframes fade-in {
    from{
        opacity: 0;
    }
    to{
        opacity: 1;
    }
}

@keyframes fade-out {
    from{
        opacity: 1;
    }
    to{
        opacity: 0;
        visibility: hidden;
    }
}

.content-notice-wrapper {
    background-color: rgb(0, 0, 0, 0.4);
    position: fixed;
    inset: 0;
    z-index: 1000;
    display: grid;
    place-items: center;
}

.content-notice {
    position: absolute;
    background-color: white;
    margin: 1rem;
    max-width: min(600px, 90vw);
    max-height: max(400px, 90vh);
    padding: 1.5rem;
    border-radius: 0.5rem;

    @media (min-width: 768px) {
        text-align: center;
    }

    &__title {
        font-size: 1.7rem;
        font-weight: bold;
        color: var(--purple);

        @media (min-width: 768px) {
            font-size: 2rem;
        }
    }

    &__body {
        margin-top: 1rem;
        font-weight: 300;
        line-height: 1.5;
        font-size: 1rem;
    }

    &__footer {
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 1rem;
        margin-top: 2.5rem;
        flex-wrap: wrap;
    }
}

.splash-screen{
    --margin-container: 10vw;
    position: fixed;
    inset: 0;
    background: linear-gradient(0deg, rgba(0,0,0,0.9), transparent 50%), url("../assets/img/galaxy.png"), var(--purple-dark);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    color: white;
    user-select: none;
    transition: visibility 0s, opacity 1s ease-in;
    z-index: 100;
    display: flex;
    flex-direction: column;

    &.hide {
        animation: fade-out 2s 500ms forwards;

        .button {
            animation: fade-out 1.5s forwards;
        }
    }

    &__content{
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-inline: var(--margin-container);
        animation: fade-in 4s forwards;

        h2{
            font-size: 2.5rem;
            margin-block: 0.75rem;
            
            @media (max-width: 400px) {
                font-size: 1.8rem;
            }
        }
        h1{
            margin-top: 1rem;
            font-size: 3rem;

            @media (max-width: 400px) {
                font-size: 2.3rem;
            }
        }

        .logo{
            font-size: 2.5rem;

            @media (max-width: 400px) {
                font-size: 2rem;
            }
        }

        .button {
            opacity: 0;
            max-width: 400px;
            animation: fade-in 200ms 1s forwards;

            &:not(.cta) {
                background-color: var(--pink);
            }
        }
    }

    &__footer{
        margin-inline: 5vw 3vw;
        font-weight: 300;
        font-size: 0.8rem;
        line-height: 1.5;
        opacity: 0.8;
        margin-bottom: 1.5rem;
        display: flex;
        column-gap: 0.6rem;

        @media (min-width: 512px) {
            margin-inline: var(--margin-container);
            column-gap: 2rem;
            font-size: 0.9rem;
        }


        .logos {
            flex-basis: 33%;
            flex-shrink: 0;
            display: flex;
            flex-wrap: wrap;
            column-gap: 1.3rem;
            row-gap: 0.2rem;
            margin-top: 1rem;
            margin-left: auto;
            justify-content: end;

            img {
                width: 100%;
                max-width: 120px;

                @media (max-width: 400px) {
                    max-width: 100px;
                }
            }
        }
    }

    .credits-popup {
        h2,
        h3 {
            color: var(--purple);
        }
        h2 {
            font-weight: 700;
        }
        h3 {
            font-weight: 400;
        }
        p {
            font-weight: 300;
        }
        button {
            appearance: none;
            border: none;
            background: none;
            font-family: var(--font);
            color: var(--purple);
            font-weight: 800;
            text-decoration: underline;
            padding: 0;
        }
    }
}

.legal-links {
    a {
        font-size: 0.85rem;
        color: white;
        text-decoration: none;
        font-weight: 300;
    }
}

.index > a{
    text-decoration: none;
    -webkit-tap-highlight-color: transparent;

    @media (pointer:coarse){
        &:active{
            img{
                transform: rotateZ(180deg) scale(0.9);
            }
        }
    }
}

.index-item{
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;
    border-bottom: 1px solid var(--gray);

    &__index {
        font-size: 3rem;
        color: var(--purple);
        margin-block: 0;
        border-right: 2px solid var(--purple-light);
        padding-right: 1rem;
        text-align: center;
        min-width: 35px;
        flex-shrink: 0;
    }

    &__title {
        flex-grow: 1;
        margin-inline: 1rem;
        font-size: 1.2rem;
        font-weight: bold;
        color: var(--purple-dark);
    }

    &__icon{
        width: 50px;
        transform: rotateZ(180deg);
        transition: transform 200ms ease;

        @media (pointer: fine) {
            &:hover{
                transform: rotateZ(180deg) scale(1.1);
            }
        }

        @media (pointer: coarse) {
            &:active{
                transform: rotateZ(180deg) scale(1.1);
            }
        }
    }
}

.subindex {
    color: var(--purple);
    font-size: 1.2rem;
}

figure.table {
    overflow-x: auto;
    margin: 0;
}

.abstract-container {
    position: absolute;
    inset: 0;
    background-color: white;
    z-index: 10;
    overflow: auto;
    border-radius: 0.5rem;

    .abstract__heading {
        font-size: 1.2rem;
        font-weight: 500;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: white;
        box-shadow: 0 4px 10px 0 rgb(0, 0, 0, 0.15);
        position: sticky;
        top: 0;
        padding: 1rem;
    }

    .abstract__content {
        padding: 1rem;
        font-size: 0.9rem;
        line-height: 1.6;
        font-weight: 300;
        overscroll-behavior: contain;
    }
}