.popup {
	position: fixed;
	inset: 0;
	z-index: 50;
	background-color: white;
	border-radius: 0.5rem;
	padding-block: 0.5rem 1rem;
	display: flex;
	flex-direction: column;
	color: black;
	max-width: min(800px, 90vw);
	max-height: max(400px, 90vh);
	margin: auto;
	isolation: isolate;

	&__backdrop {
		position: fixed;
		inset: 0;
		background-color: rgb(0, 0, 0, 0.4);
		mix-blend-mode: overlay;
		z-index: -1;
	}


	&__close {
		text-align: end;
		padding-inline: 0.5rem;
		background-color: white;
		cursor: pointer;
	}

	&__content {
		position: static;
		flex-grow: 1;
		overflow-y: auto;
		overscroll-behavior: contain;
		padding-inline: 1rem;
		background-color: white;

		.button {
			font-weight: 400;
			font-size: 0.8rem;
			width: fit-content;
			padding: 0.5rem;
		}

		.map-wrapper {
			display: flex;
		}

		iframe {
			width: 100%;
			min-height: 500px;
			border-radius: 6px;
		}
	}

	.loader {
		display: none;
	}

	&.loading {
		.popup__content {
			display: grid;
			place-items: center;
		}

		.loader {
			display: block;
		}
	}
}

.loader {
	border: 4px solid var(--purple-light);
	border-left-color: transparent;
	border-radius: 50%;
	width: 36px;
	height: 36px;

	animation: spin 1s linear infinite;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

.img-wrapper {
	width: 100%;
	height: 100%;
	display: grid;
	place-items: center;
}

.reference-wrapper {
	padding-block: 1rem;

	&:first-child {
		padding-block-start: 0.5rem;
	}

	&:not(:last-child) {
		border-bottom: 2px solid rgb(0, 0, 0, 0.1);
	}
	
	.reference-item {
		display: flex;
		flex-direction: column;
		row-gap: 0.6rem;
	}

	.reference__title {
		font-size: 1.2rem;
		font-weight: 500;
	}

	.reference__authors {
		font-size: 0.85rem;
		font-weight: 300;
	}

	.reference__basic {
		color: var(--purple);
		font-weight: 500;
		font-size: 0.95rem;
	}

	.reference__link {
		color: var(--purple-light);
		font-size: 0.8rem;
		color: var(--pink);
		text-decoration: unset;
		font-weight: 300;
		width: fit-content;
	}

	.button {
		margin-top: 0.5rem;
	}
}