.references-page {
	.references-list {
		background-color: white;
		
		.reference-item {
			padding: 1rem;
		}
	}

	.chapter-wrapper .content{
		padding: 0;
	}

	.abstract-container {
		isolation: isolate;
		background-color: white;
		margin: auto;
		max-width: min(600px, 90vw);
		max-height: min(800px, 90vh);
		z-index: 1000;

		&__backdrop {
			position: fixed;
			inset: 0;
			background-color: rgb(0, 0, 0, 0.4);
			mix-blend-mode: overlay;
			z-index: -1;
		}
	}
}